













































































































































import { Vue, Component } from 'nuxt-property-decorator';
import scripts from './scripts';

@Component({
    name: 'InfopackCardFullHorizontal',
    mixins: [scripts],
})
export default class InfopackCardFullHorizontal extends Vue {}
