import { render, staticRenderFns } from "./vertical.vue?vue&type=template&id=5a3734d6&scoped=true&"
import script from "./vertical.vue?vue&type=script&lang=ts&"
export * from "./vertical.vue?vue&type=script&lang=ts&"
import style0 from "./vertical.vue?vue&type=style&index=0&id=5a3734d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3734d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ITooltippedText: require('/app/components/i/tooltipped-text/index.vue').default,PKeywords: require('/app/components/p/keywords/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VDivider,VHover,VIcon,VImg,VList,VListItem,VListItemIcon})
