import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3e4f3e2b&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProcessCardFullHorizontal: require('/app/components/process/card/full/horizontal.vue').default,InfopackCardFullHorizontal: require('/app/components/infopack/card/full/horizontal.vue').default,ProcessCardFullVertical: require('/app/components/process/card/full/vertical.vue').default,InfopackCardFullVertical: require('/app/components/infopack/card/full/vertical.vue').default})
