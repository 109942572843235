
















import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({})
export default class TooltippedText extends Vue {
    @Prop({ required: true })
    value: string;

    @Prop({ default: 60 })
    enabledLength: number;

    @Prop({ default: '' })
    targetClasses: string;
}
