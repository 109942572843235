import { Vue, Component, Prop, Action } from 'nuxt-property-decorator';
import dates from '@/mixinsjs/dates';
import packCommon from '@/mixinsjs/pack-common';
import copyToClipboard from '@/mixinsjs/copyToClipboard';

@Component({
    name: 'ProcessCardFull',
    mixins: [dates, packCommon, copyToClipboard],
})
export default class ProcessCardFull extends Vue {
    @Prop({ required: true })
    theProcess: any;

    @Prop()
    width: string;

    @Prop()
    background: string;

    get storage() {
        return process.env.VUE_APP_API_STORAGE_URL;
    }

    get avatarStorage() {
        return this.mxStorage();
    }

    get hasAvatar() {
        return this.mxHasAvatar(this.theProcess);
    }

    get levelsCount() {
        return this.theProcess.infopackTabs.length;
    }

    get levels() {
        return [...this.theProcess.infopackTabs].splice(0, 5);
    }

    get hasPrice() {
        return this.theProcess.subscriptions?.[0]?.price;
    }

    @Action('setSnackBar') setSnackBar;

    async copyToClipboard() {
        try {
            await this.mxCopyToClipboard(`${this.$config.domain}/process/${this.theProcess.token}`);
            this.setSnackBar({
                toggle: true,
                message: this.$t('info.messages.titleCopied').replace(
                    'TITLE',
                    this.$t('info.public.link')
                ),
                color: 'green',
            });
        } catch (error) {
            this.setSnackBar({
                toggle: true,
                message: this.$t('info.messages.copyingFailed'),
                color: 'red',
            });
        }
    }
}
