import { Vue, Component, Prop, Action } from 'nuxt-property-decorator';
import dates from '@/mixinsjs/dates';
import packCommon from '@/mixinsjs/pack-common';
import copyToClipboard from '@/mixinsjs/copyToClipboard';

@Component({
    name: 'InfopackCardFull',
    mixins: [dates, packCommon, copyToClipboard],
})
export default class InfopackCardFull extends Vue {
    @Prop({ required: true })
    theInfopack: any;

    @Prop()
    width: string;

    get mobile() {
        return !this.$breakpoint.mdAndUp;
    }

    get storage() {
        return process.env.VUE_APP_API_STORAGE_URL;
    }

    get metadata() {
        return this.theInfopack.metadata || {};
    }

    get metadataKeysLength() {
        if (!this.theInfopack.metadata) {
            return 0;
        }
        return Object.values(this.theInfopack.metadata).some((val) => val > 0);
    }

    get avatarStorage() {
        return this.mxStorage();
    }

    get hasAvatar() {
        return this.mxHasAvatar(this.theInfopack);
    }

    get hasPrice() {
        return this.theInfopack.subscriptions?.[0]?.price;
    }

    get chaptersCount() {
        return this.theInfopack.infopackTabs.reduce(
            (count, chap) => count + 1 + (chap.children?.length || 0),
            0
        );
    }

    @Action('setSnackBar') setSnackBar;

    async copyToClipboard() {
        try {
            await this.mxCopyToClipboard(
                `${this.$config.domain}/infopacks/${this.theInfopack.token}`
            );
            this.setSnackBar({
                toggle: true,
                message: this.$t('info.messages.titleCopied').replace(
                    'TITLE',
                    this.$t('info.public.link')
                ),
                color: 'green',
            });
        } catch (error) {
            this.setSnackBar({
                toggle: true,
                message: this.$t('info.messages.copyingFailed'),
                color: 'red',
            });
        }
    }
}
