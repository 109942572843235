



















import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({
    name: 'Keywords',
})
export default class Keywords extends Vue {
    @Prop({ required: true })
    keywords: any;

    @Prop({ required: true })
    type: any;

    get keywordsColors() {
        return ['#fff3e0', '#e8f5e9', '#e0f7fa'];
    }

    get keywordsArray() {
        return this.keywords
            ? this.keywords
                  .split(/,|،/g)
                  .splice(0, 3)
                  .filter((itm) => itm.length)
            : [];
    }
}
