

















import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({
    name: 'FullCard',
})
export default class FullCard extends Vue {
    @Prop({ required: true })
    type: string;

    @Prop({ required: true })
    itm: any;

    @Prop({ required: true })
    idx: number;

    public Types = {
        H: 'horizontal',
        V: 'vertical',
    };
}
